<template>
    <div>
        <section class="my-0" :class="{'section': desktop}">
            <card class="border-0">
                <div class="row justify-content-md-center">
                    <div v-if="responses.length > 0" :class="{'col-lg-6': desktop}">
                        <base-alert v-if="isRealtor && !this.$store.state.logged_in" type="primary">
                            <i18n path="property.invite" tag="span" class="alert-inner--text">
                                <template v-slot:register>
                                    <router-link style="color: white; font-weight: bold;" :to="'/#corretor'"><span @click="$gtag.event('realtor_invited')">Cadastre-se!</span></router-link>
                                </template>
                            </i18n>
                        </base-alert>
                        <div class="shadow">
                            <template>
                                <div v-for="(property, n) in responses" :key="property._id">
                                    <response-card :property="property"
                                                   :type="type"
                                                   source="ad"></response-card>
                                    <div v-if="n + 1 < responses.length" class="mt-5"></div>
                                </div>
                            </template>
                        </div>
                    </div>                    
                </div>
            </card>
            
        </section>
        <div class="vld-parent">
            <loading :active.sync="isLoading"
                     :can-cancel="true"
                     loader="dots"
                     :is-full-page="true"
                     :opacity="1"
                     color="#5271ff"></loading>
        </div>
    </div>
</template>

<script>
    import ResponseCard from '../components/ResponseCard';
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import $ from 'jquery';

    export default {
        components: {
            ResponseCard,
            Loading
        },

        name: 'Property',

        data() {
            return {
                responses: [],
                type: (this.$store.state.logged_in ? this.$store.state.type : 'requestor'),
                isLoading: true,
                desktop: screen.width > 750,
                isRealtor: false,

            }
        },

        methods: {
            updateResponses(documents) {
                this.responses = documents;
            },
            updateInput(key, value) {
                this.$data[key] = value;
            },
            getInput(key) {
                return this.model[key]
            },
            wipeOnBlur(event) {
                event.target.value = '';
            },
            ranger(value) {
                var tag = ' months'
                if (value == 1) {
                    tag = ' month'
                } else if (value == 12) {
                    tag = ' year'
                    value = 1
                }
                return value + tag;
            },
            openContactModal() {
                this.modal = true;
            },
        },

        mounted() {
            this.$gtag.set({ 'user_id': this.$store.state._id });
            var updateResponses = this.updateResponses;
            var data = this.$data;
            const router = this.$router;

            if (location.hash == '#realtor' || location.hash == '#corretor') {
                this.isRealtor = true;
            }

            $.ajax({
                url: 'https://api.immobl.com/property/' + this.$route.params.property_id,
                contentType: 'application/json;charset=UTF-8',
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true,
                type: 'GET',
                success: function (resp) {
                    if (resp.success) {
                        updateResponses(resp.responses);
                        data.isLoading = false;
                    } else {
                        router.push('/dashboard')
                    }
                },
                //error: function (err) {
                //    //console.log(err);
                //}
            });

        },

    };
</script>

<style scoped>
    .Gmap {
        width: 65%;
        height: 650px;
        float: left;
        /*bottom: 0;*/
        position: absolute;
    }

    .properties {
        width: 35%;
        float: right;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 650px;
        bottom: 0;
        position: relative;
        margin-bottom: 0px;
    }

    .form {
        width: 50%;
        margin-left: 6px;
    }

    img {
        max-width: 70px;
        float: left;
    }
</style>
