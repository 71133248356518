<template>
    <div>
        <b-card no-body class="input-group-alternative">
            <template v-slot:header>
                <h5 class="mb-0" style="font-size: small; font-weight: bold; text-transform:uppercase">
                    {{$t('reply.request.property.' + property.property)}} <span v-if="property.type == 'buy'">{{$t('find.type.buy')}}</span><span v-else>{{$t('find.type.rent')}}</span> por {{property.from.name}}
                </h5>
                <small>{{$t('source.vivareal')}}</small>
            </template>
            <b-carousel v-if="property.images.length > 0"
                        id="carousel-1"
                        :key="property._id"
                        :controls="property.images.length > 1"
                        :interval="0"
                        :indicators="property.images.length > 1">
                <b-carousel-slide v-for="(image, n) in property.images.slice(0, 5)"
                                  :key="n"
                                  :img-src="image.path">
                </b-carousel-slide>
            </b-carousel>

            <b-card-title style="padding: 5px;" class="mt-3">
                <span class="col-md-auto">{{property.location}}</span>
            </b-card-title>

            <b-card-text>
                <div class="justify-content-md-center">
                    <span class="col-md-auto lead" style="font-weight: 500;">{{$t('global.currency.real')}} {{property.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}}</span>
                    <span class="col-md-auto lead" style="font-weight: 500;">{{property.area}} {{$t('global.meter')}}</span>
                </div>
                <!--<div class="mt-2 row justify-content-md-center">
                    <span v-if="property.rent_time > 0" class="col-md-auto">{{$tc('response.time', property.rent_time, {quantity: property.rent_time})}}</span>
                    <span class="col-md-auto" v-if="property.availability">{{$t('response.availability')}}</span>
                    <span class="col-md-auto" v-else>{{$t('response.available', {date: property.available_date})}}</span>
                </div>-->
                <div class="mt-2 justify-content-md-center lead">
                    <!--<badge class="goodge" v-if="property.floor > 0" type="primary">
        <i class="fa fa-building" aria-hidden="true"></i>
        {{$tc('response.floor', property.floor, { quantity: parseInt(property.floor) })}}
    </badge>-->
                    <span class="badge goodge" v-if="property.suite > 0" type="primary">
                        <i class="fa fa-bath" aria-hidden="true"></i>
                        {{$tc('response.suite', property.suite, { quantity: parseInt(property.suite) })}}
                    </span>
                    <span class="badge goodge" v-if="property.room > 0" type="primary">
                        <i class="fa fa-bed" aria-hidden="true"></i>
                        {{$tc('response.room', property.room, { quantity: parseInt(property.room) })}}
                    </span>
                    <span class="badge goodge" v-if="property.garage > 0" type="primary">
                        <i class="fa fa-car" aria-hidden="true"></i>
                        {{$tc('response.garage', property.garage, { quantity: parseInt(property.garage) })}}
                    </span>
                    <span class="badge goodge" v-if="property.furnished" type="primary">
                        <i class="fa fa-television" aria-hidden="true"></i>
                        {{$t('response.furnished')}}
                    </span>
                    <span class="badge goodge" v-if="property.pet != '0'" type="primary">
                        <i class="fa fa-paw" aria-hidden="true"></i>
                        {{$tc('response.pet', property.pet)}}
                    </span>
                </div>
            </b-card-text>
            <template v-slot:footer>
                <div v-if="property.detail">
                    <div class="row">
                        <div class="col colText">
                            {{property.detail}}
                        </div>
                    </div>
                </div>
                <div v-if="property.feature != undefined">
                    <div v-if="property.feature.length > 1" class="mt-3">
                        <div class="row">
                            <small class="col colText">{{$t('response.feature')}}</small>
                        </div>
                        <div class="row">
                            <div class="col colText">
                                <span v-for="(feat, n) in property.feature" :key="n">
                                    <span class="badge">{{feat}}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-3" v-if="parseInt(property.tax) > 0">
                    <div class="row">
                        <small class="col colText">{{$t('reply.label.tax')}}</small>
                    </div>
                    <div class="row">
                        <div class="col colText">{{$t('global.currency.real') + ' ' + property.tax}}</div>
                    </div>
                </div>
                <div class="mt-3" v-if="property.visit">
                    <div class="row">
                        <small class="col colText">{{$t('reply.label.visit')}}</small>
                    </div>
                    <div class="row">
                        <div class="col colText">
                            <span v-if="property.visit == 'limitedBusinessTime'" class="badge">{{$t('reply.sublabel.visit.limited')}}</span>
                            <span v-if="property.visit == 'allBusinessTime'" class="badge">{{$t('reply.sublabel.visit.all')}}</span>
                            <span v-if="property.visit == 'specific'" class="badge">
                                <span v-for="(datetime, n, i) in property.visit_dates" :key="n">{{datetime}}{{i + 1 == Object.keys(property.visit_dates).length ? '.' : ', '}}</span>
                            </span>
                            <span v-if="property.visit == 'onlineTour'" class="badge">{{$t('reply.sublabel.visit.online')}}</span>
                        </div>
                    </div>
                </div>
                <div class="mt-3" v-if="property.document">
                    <div class="row">
                        <small class="col colText">{{$t('reply.label.document')}}</small>
                    </div>
                    <div class="row">
                        <div class="col colText">{{property.document}}</div>
                    </div>
                </div>
                <div class="mt-3" v-if="property.condition">
                    <div class="row">
                        <small class="col colText">{{necessaryCondition(property.type)}}</small>
                    </div>
                    <div class="row">
                        <div class="col colText">{{property.condition}}</div>
                    </div>
                </div>
                <div class="mt-3" v-if="property.special">
                    <div class="row">
                        <small class="col colText">{{$t('reply.label.special')}}</small>
                    </div>
                    <div class="row">
                        <div class="col colText">{{property.special}}</div>
                    </div>
                </div>

                <!--<div v-if="type == 'realtor'" class="mt-2">
        <small>
            <i>
                <span v-if="property.static">{{$t('reply.request.warning.static')}}</span>
                <span v-else>{{$t('reply.request.warning.dynamic')}}</span>
            </i>
        </small>
    </div>-->
                <div v-if="type == 'requestor'" class="my-4">
                    <div v-if="property.reaction == 'standby'" class="row justify-content-center">
                        <base-button class="mt-4 col-md-auto" type="primary" @click="chooseResponse(property._id, property.from, 'accept'); $gtag.event('contact_realtor')">{{$t('response.button.contact')}}</base-button>
                        <base-button class="mt-4 col-md-auto" type="danger" @click="chooseResponse(property._id, property.from, 'reject'); $gtag.event('reject_offer')">{{$t('response.button.reject')}}</base-button>
                    </div>
                    <div v-else class="row justify-content-center">
                        <base-button class="mt-3" type="success" icon="fa fa-whatsapp my-float" @click="contactRealtor(property, 'realtor'); $gtag.event('contact_realtor');">
                            Ver contato
                        </base-button>
                    </div>
                </div>
                <div v-if="type == 'realtor' && !['accept', 'reject'].includes(property.reaction) && property.from._id == user_id">
                    <base-button style="float: right;" class="mt-4" type="warning" @click="chooseResponse(property._id, property.from, 'exclude'); $gtag.event('exclude_offer')">{{$t('response.button.exclude')}}</base-button>
                </div>
            </template>
        </b-card>
        <modal :show.sync="modal"
               gradient="success"
               modal-classes="modal-danger modal-dialog-centered">
            <h6 slot="header" class="modal-title" id="modal-title-notification">{{$t('find.contact.info')}}</h6>

            <div class="py-3 text-center">
                <i class="ni ni-chat-round ni-4x"></i>
                <p class="mt-4">{{$t('find.contact.interest', {name: contact.name.full})}}</p>
                <i18n path="response.contact.contact" tag="p">
                    <template v-slot:phone>
                        <a style="color: white; font-weight: bold;" target="_blank" @click="$gtag.event('contact_realtor_number')" :href="(desktop ? 'https://web' : 'https://api') + '.whatsapp.com/send?phone=' + contact.number.international + '&text=' + $t('find.contact.text.whatsapp', {name: contact.name.full, location: contact.location, type: contact.type, price: contact.price, link: 'https://immobl.com/property/' + property._id})">{{contact.number.national}}</a>
                    </template>
                    <template v-slot:email>
                        <a style="color: white; font-weight: bold;" @click="$gtag.event('contact_realtor_email')" :href="'mailto:' + contact.email + '?subject=Cliente da Immobl' + '&body=' + $t('find.contact.text.whatsapp', {name: contact.name.full, location: contact.location, type: contact.type, price: contact.price, link: 'https://immobl.com/property/' + property._id})">{{contact.email}}</a>
                    </template>
                </i18n>
            </div>
        </modal>
    </div>
</template>
<script>
    import $ from 'jquery';
    import BaseButton from '../components/BaseButton'
    import {
        BCard,
        BCardText,
        BCardTitle,
        BCarousel,
        BCarouselSlide
    } from 'bootstrap-vue'

    export default {
        name: "response-card",
        components: {
            BaseButton,
            BCard,
            BCardText,
            BCardTitle,
            BCarousel,
            BCarouselSlide
        },
        props: {
            property: {
                type: Object,
                description: "Request data",
            },
            type: {
                type: String,
            },
            source: { default: 'reply' }
        },

        data() {
            return {
                modal: false,
                contact: {
                    name: {},
                    email: '',
                    number: {},
                    location: '',
                    type: '',
                    price: '',
                },
                desktop: screen.width > 700,
                user_id: this.$store.state._id
            }
        },

        methods: {
            chooseResponse(property, agent, reaction) {
                var source = this.source;
                $.ajax({
                    url: 'https://api.immobl.com/react/' + property + '?reaction=' + reaction + '&source=' + source,
                    contentType: 'application/json;charset=UTF-8',
                    xhrFields: {
                        withCredentials: true
                    },
                    crossDomain: true,
                    type: 'POST',
                    data: JSON.stringify({
                        data: agent
                    }),
                    //success: function (resp) {
                    //},
                    //error: function (err) {
                    //    //console.log(err);
                    //}
                });
                $("html, body").animate({ scrollTop: 0 }, 1000);
                this.property.reaction = reaction;
                this.$router.push('/dashboard');
            },

            necessaryCondition(type) {
                type = this.$t('reply.' + type);
                return this.$t('reply.label.condition', { type: type });
            },

            contactRealtor(property, type) {
                var location = property.location.slice(0, property.location.indexOf(',', property.location.indexOf('-')));
                var propertyType = this.$t('find.type.' + property.type);
                var price = this.$t('global.currency.real') + ' ' + property.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                var link = 'https://immobl.com/property/' + property._id + '/%23corretor';
                var whatsapp_url = (this.desktop ? 'https://web' : 'https://api') + '.whatsapp.com/send?phone=' + property.from.number + '&text=' + this.$t('find.contact.text.whatsapp', { name: property.from.name, location: location, type: propertyType, price: price, link: link })

                if (property.from.type == 'external') {
                    this.$gtag.event('contact_realtor_external');
                    window.open(whatsapp_url, '_blank');
                } else {

                    var openContactModal = this.openContactModal;
                    var updateData = this.updateData;
                    var _id = property.from._id;

                    $.ajax({
                        url: 'https://api.immobl.com/contact/' + _id + '?type=' + type,
                        contentType: 'application/json;charset=UTF-8',
                        xhrFields: {
                            withCredentials: true
                        },
                        crossDomain: true,
                        type: 'GET',
                        success: function (resp) {
                            if (resp.document != undefined) {
                                resp.document.location = location;
                                resp.document.type = propertyType;
                                resp.document.price = price;
                                updateData('contact', resp.document);
                            }
                        },
                        //error: function (err) {
                        //    //console.log(err);
                        //}
                    });

                    openContactModal();
                }
            },
            updateData(key, value) {
                this.$data[key] = value;
            },
            openContactModal() {
                this.modal = true;
            },
        },
    };
</script>
<style scoped>
    .colText {
        text-align: left;
    }

    .goodge {
        font-size: 16px;
        font-weight: 500;
        margin: 5px;
        text-transform: lowercase;
    }
</style>
